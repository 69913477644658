<template>
  <div>
    <PageHeader>
      <template slot="right">
        <b-button 
          v-if="$allowPermission('agent:manage.bank-account')"
          to="/bank-accounts/create" variant="primary">
          <i class="uil-plus mr-1"></i>
          {{ $t('buttons.add') }}
        </b-button>
      </template>
    </PageHeader>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row class="mb-3">
        <b-col>
          <LimitSelect v-model="selectedLimit"/>
        </b-col>
        <b-col v-if="isOwner" cols="3">
          <MasterSelectInput 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col v-if="isOwner || isMaster" cols="3">
          <AgentsSelectInput
            :master-id="selectedMaster"
            :value="selectedAgent"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
      </b-row>
      <BankAccountsTable
        :bank-accounts="bankAccounts"
        :is-loading="user.isFetchingBankAccounts"
        @delete="onDeleteBankAccount"
        @select="navigateRoute"
        @transfer="transferHandler"
      />
      <!-- pagination -->
      <PaginationInput
        :per-page="limit"
        :total="total"
        @update="(val) => (currentPage = val)"
      />

      <TransferModal :bank-account="selectedBankAccount" />
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการบัญชีธนาคาร',
  },
  components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    BankAccountsTable: () =>
      import('@components/bank-accounts/table-list'),
    TransferModal: () =>
      import('@components/forms/bank-account/transfer-modal')
  },
  data() {
    return {
      currentPage: 1,
      selectedMaster: '',
      selectedAgent: '',
      selectedLimit: 20,
      selectedBankAccount: null
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isOwner', 'isMaster', 'userBankAccounts']),
    bankAccounts() {
      return this.userBankAccounts.items || []
    },
    limit() {
      return this.userBankAccounts?.meta?.itemsPerPage
    },
    total() {
      return this.userBankAccounts?.meta?.totalItems || 0
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBankAccounts', 'deleteBankAccount']),
    fetchData() {
      this.fetchBankAccounts({
        limit: this.limit,
        offset: this.offset,
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
      })
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    navigateRoute(value) {
      this.$router.push(`/bank-accounts/detail/${value.id}`)
    },
    async onDeleteBankAccount(id) {
      await this.deleteBankAccount(id)
      this.fetchData()
    },
    transferHandler(data) {
      this.selectedBankAccount = data
      this.$nextTick(() => this.$bvModal.show('modal-transfer'))
    }
  },
}
</script>
